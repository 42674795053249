a:link, a:visited, a:hover, a:active {
  text-decoration: none;
}

.MuiPickersSlideTransition-slideEnter-left,
.MuiPickersSlideTransition-slideEnter-right,
.MuiPickersSlideTransition-slideExit
{
  transition-duration: 0s !important;
}

*:focus {
  outline:none
}

/* .MuiFilledInput-root:after
{
  border-bottom: 1px solid rgb(89,112,133)
} */

input,
textarea
{
  font-size: 16px !important;
}

.slick-dots {
  position: unset !important;
}

.slick-next {
  display: none !important;
}

.slick-current {
  z-index: 99 !important;
}

.slick-dots li.slick-active button:before {
  color:rgb(225, 225, 225) !important;
}

.slick-dots li button:before {
  color:rgb(155, 155, 155) !important;
}